import React, { useEffect, useMemo, useState } from 'react'
import { Button, Col, DatePicker, Divider, Layout, Row } from 'antd'
import { Content, Header } from 'antd/es/layout/layout'
import { DatePickerProps } from 'antd/lib'
import { format, subWeeks } from 'date-fns'
import dayjs, { Dayjs } from 'dayjs'
import { useNavigate } from 'react-router-dom'
import styles from './Growth.module.scss'
import { AppConstants } from '../../../AppContants'
import Loading from '../../../components/Loading/Loading'
import Table, { IColumn, TableRow } from '../../../components/Table'
import exportSvg from '../../../images/export.svg'
import { getGrowthRecordAsync, setSelectedRange } from '../../../redux/admin/growth'
import { Lead } from '../../../redux/admin/growth/interface'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { StatusEnum } from '../../../redux/type'
import { formatPhoneNumber } from '../../../utils/FormatPhoneNumber'

const Growth = () => {
  const dispatch = useAppDispatch()
  const growthRecord = useAppSelector((state) => state.admin.growth.growthRecord)
  const selectedRange = useAppSelector((state) => state.admin.growth.selectedRange)
  const growthRecordStatus = useAppSelector((state) => state.admin.growth.growthRecordStatus)
  const apiError = useAppSelector((state) => state.admin.user.error)
  const [startDate, setStartDate]: any = useState()
  const [endDate, setEndDate]: any = useState()
  const Role = localStorage.getItem(AppConstants.CURRENTROLE_TOKEN)
  const navigate = useNavigate()
  console.log(selectedRange, 'selected range')

  const columns: Array<IColumn> = [
    {
      capitalize: true,
      displayName: 'First Name',
      key: 'firstname',
      size: 30,
    },
    {
      capitalize: true,
      displayName: 'Last Name',
      key: 'lastname',
      size: 30,
    },
    {
      capitalize: false,
      displayName: 'Email',
      key: 'email',
      size: 30,
    },
    {
      capitalize: false,
      displayName: 'Phone',
      key: 'phone',
      size: 30,
    },
    {
      capitalize: true,
      displayName: 'Lead Source',
      key: 'leadSource',
      size: 30,
    },
    {
      capitalize: true,
      displayName: 'Date Created',
      key: 'dateCreated',
      size: 30,
    },

    {
      capitalize: false,
      displayName: 'Booking Date',
      key: 'bookingDate',
      size: 30,
    },
    {
      capitalize: false,
      displayName: 'Show Up',
      key: 'showedUp',
      size: 30,
    },
    {
      capitalize: false,
      displayName: 'Convert',
      key: 'converted',
      size: 30,
    },
    {
      capitalize: true,
      displayName: 'Doctor',
      key: 'doctor',
    },
  ]

  const currentDate = useMemo(() => {
    return format(new Date(), 'yyyy-MM-dd')
  }, [])

  const oneWeekBeforeDate = useMemo(() => {
    return format(subWeeks(new Date(), 1), 'yyyy-MM-dd')
  }, [])

  const startDateDefaultValue = [dayjs(selectedRange?.startDate || oneWeekBeforeDate)]
  const endDatedefaultValue = [dayjs(selectedRange?.endDate || currentDate)]

  const rows: Array<TableRow> | any = useMemo(() => {
    return growthRecord?.records?.map((d: any) => {
      return {
        id: d?.id || '',
        firstname: d?.firstname || '-',
        lastname: d?.lastname || '-',
        email: d?.email || '-',
        phone: formatPhoneNumber(d?.phone) || '-',
        leadSource: d?.leadSource || '-',
        dateCreated: !d?.dateCreated ? '-' : format(d?.dateCreated, 'MM/dd/yyyy'),
        bookingDate: !d?.bookingDate ? '-' : format(d?.bookingDate, 'MM/dd/yyyy'),
        showedUp: d?.showedUp === true ? 'Yes' : d?.showedUp === false ? 'No' : d?.showedUp === 2 ? 'N/A' : '-',
        converted: d?.converted ? 'Yes' : 'No',
        doctor: d?.doctor || '-',
      }
    })
  }, [growthRecord])

  useEffect(() => {
    dispatch(
      getGrowthRecordAsync({
        startDate: selectedRange?.startDate || oneWeekBeforeDate,
        endDate: selectedRange?.endDate || currentDate,
      }),
    )
    setStartDate(selectedRange?.startDate || oneWeekBeforeDate)
    setEndDate(selectedRange?.endDate || currentDate)
  }, [])

  const onApplyRange = async () => {
    if (startDate && endDate) {
      dispatch(
        getGrowthRecordAsync({
          startDate: startDate,
          endDate: endDate,
        }),
      )
      dispatch(setSelectedRange({ startDate: startDate, endDate: endDate }))
    }
  }

  const onChangeStartDate: DatePickerProps<Dayjs[]>['onChange'] = (date, dateString) => {
    setStartDate(dateString)
  }
  const onChangeEndDate: DatePickerProps<Dayjs[]>['onChange'] = (date, dateString) => {
    setEndDate(dateString)
  }

  function convertToCSV(lead: Lead[]): Blob {
    const headers = Object.keys(lead[0]).filter((key) => key !== 'id' && key !== 'referralCode' && key !== 'booked')

    const rows = lead.map((obj: any) => {
      return headers.map((key) => {
        const value = obj[key]
        if (value === null) {
          return '-'
        }
        if (typeof value === 'boolean') {
          return value ? 'Yes' : 'No'
        }
        return value
      })
    })

    const headersCapitalized = headers.map((key) => {
      return key.charAt(0).toUpperCase() + key.slice(1)
    })

    const csvString = [headersCapitalized.join(','), ...rows.map((row) => row.join(','))].join('\n')

    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' })

    return blob
  }

  const exportReports = async () => {
    const blob = convertToCSV(growthRecord?.records || [])
    if (blob) {
      const url = window.URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = 'KIRO_Growth_Report.csv'

      document.body.appendChild(a)
      a.click()

      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    } else {
      alert('Failed to export growth report, Please try again later.')
    }
  }

  return (
    <Layout>
      <Header style={{ borderBottom: '1px solid #2D2C3C' }}></Header>
      <Content style={{ overflowY: 'scroll' }}>
        <div style={{}} className={styles.datePickerContainer}>
          <DatePicker
            placeholder="From"
            className={styles.startDatePicker}
            onChange={onChangeStartDate}
            defaultValue={startDateDefaultValue}
          ></DatePicker>
          <DatePicker
            placeholder="To"
            className={styles.endDatePicker}
            onChange={onChangeEndDate}
            defaultValue={endDatedefaultValue}
          ></DatePicker>
          <Button children={'Apply'} type="primary" className={styles.applyBtn} onClick={onApplyRange}></Button>
        </div>
        {growthRecordStatus === StatusEnum.Success && (
          <div style={{ padding: 20 }}>
            <div className={styles.summaryTitle}>Summary</div>
            <Row justify={'start'} className={styles.summaryContainer} style={{ marginLeft: '1rem' }}>
              <Col
                className={styles.summaryCard}
                xs={{ span: 17 }}
                sm={{ span: 17 }}
                md={{ span: 11 }}
                lg={{ span: 11 }}
                xl={{ span: 5 }}
                xxl={{ span: 4 }}
              >
                <div className={styles.sumContainer}>
                  <div className={styles.titleColumn}>
                    <div className={styles.reportTitle}>Total Leads : </div>
                    <div className={styles.reportTitle}>Leads from Meta :</div>
                    <div className={styles.reportTitle}>Leads from Google :</div>
                    <div className={styles.reportTitle}>Leads from Referrals :</div>
                  </div>
                  <div className={styles.amountColumn}>
                    <div className={styles.reportValue}>{growthRecord?.summary?.lead?.total ?? '-'}</div>
                    <div className={styles.reportValue}>{growthRecord?.summary?.lead?.meta ?? '-'}</div>
                    <div className={styles.reportValue}>{growthRecord?.summary?.lead?.google ?? '-'}</div>
                    <div className={styles.reportValue}>{growthRecord?.summary?.lead?.referral ?? '-'}</div>
                  </div>
                </div>
              </Col>
              <Col
                className={styles.summaryCard}
                xs={{ span: 17 }}
                sm={{ span: 17 }}
                md={{ span: 11 }}
                lg={{ span: 11 }}
                xl={{ span: 5 }}
                xxl={{ span: 4 }}
              >
                <div className={styles.sumContainer}>
                  <div className={styles.titleColumn}>
                    <div className={styles.reportTitle}>Booking % (Total) : </div>
                    <div className={styles.reportTitle}>Booking % (Meta) : </div>
                    <div className={styles.reportTitle}>Booking % (Google) : </div>
                    <div className={styles.reportTitle}>Booking % (Referral) : </div>
                  </div>
                  <div className={styles.amountColumn}>
                    <div className={styles.reportValue}>
                      {growthRecord?.summary?.bookings?.total ? `${growthRecord?.summary?.bookings?.total} %` : '-'}
                    </div>
                    <div className={styles.reportValue}>
                      {growthRecord?.summary?.bookings?.meta ? `${growthRecord?.summary?.bookings?.meta} %` : '-'}
                    </div>
                    <div className={styles.reportValue}>
                      {growthRecord?.summary?.bookings?.google ? `${growthRecord?.summary?.bookings?.google} %` : '-'}
                    </div>
                    <div className={styles.reportValue}>
                      {growthRecord?.summary?.bookings?.referral
                        ? `${growthRecord?.summary?.bookings?.referral} %`
                        : '-'}
                    </div>
                  </div>
                </div>
              </Col>

              <Col
                className={styles.summaryCard}
                xs={{ span: 17 }}
                sm={{ span: 17 }}
                md={{ span: 11 }}
                lg={{ span: 11 }}
                xl={{ span: 5 }}
                xxl={{ span: 4 }}
              >
                <div className={styles.sumContainer}>
                  <div className={styles.titleColumn}>
                    <div className={styles.reportTitle}>Show % (Total) : </div>
                    <div className={styles.reportTitle}>Show % (Meta) : </div>
                    <div className={styles.reportTitle}>Show % (Google) : </div>
                    <div className={styles.reportTitle}>Show % (Referral) : </div>
                  </div>
                  <div className={styles.amountColumn}>
                    <div className={styles.reportValue}>
                      {growthRecord?.summary?.shows?.total ? `${growthRecord?.summary?.shows?.total} %` : '-'}
                    </div>
                    <div className={styles.reportValue}>
                      {growthRecord?.summary?.shows?.meta ? `${growthRecord?.summary?.shows?.meta} %` : '-'}
                    </div>
                    <div className={styles.reportValue}>
                      {growthRecord?.summary?.shows?.google ? `${growthRecord?.summary?.shows?.google} %` : '-'}
                    </div>
                    <div className={styles.reportValue}>
                      {growthRecord?.summary?.shows?.referral ? `${growthRecord?.summary?.shows?.referral} %` : '-'}
                    </div>
                  </div>
                </div>
              </Col>

              <Col
                className={styles.summaryCard}
                xs={{ span: 17 }}
                sm={{ span: 17 }}
                md={{ span: 11 }}
                lg={{ span: 11 }}
                xl={{ span: 5 }}
                xxl={{ span: 4 }}
              >
                <div className={styles.sumContainer}>
                  <div className={styles.titleColumn}>
                    <div className={styles.reportTitle}>Convert % (Total) : </div>
                    <div className={styles.reportTitle}>Convert % (Meta) : </div>
                    <div className={styles.reportTitle}>Convert % (Google) : </div>
                    <div className={styles.reportTitle}>Convert % (Referral) : </div>
                  </div>
                  <div className={styles.amountColumn}>
                    <div className={styles.reportValue}>
                      {growthRecord?.summary?.converts?.total ? `${growthRecord?.summary?.converts?.total} %` : '-'}
                    </div>
                    <div className={styles.reportValue}>
                      {growthRecord?.summary?.converts?.meta ? `${growthRecord?.summary?.converts?.meta} %` : '-'}
                    </div>
                    <div className={styles.reportValue}>
                      {growthRecord?.summary?.converts?.google ? `${growthRecord?.summary?.converts?.google} %` : '-'}
                    </div>
                    <div className={styles.reportValue}>
                      {growthRecord?.summary?.converts?.referral
                        ? `${growthRecord?.summary?.converts?.referral} %`
                        : '-'}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}
        {Role && Role !== 'ANALYTICS_VIEWER' && (
          <div style={{ padding: 20, height: '60%' }}>
            {growthRecordStatus === StatusEnum.Pending && <Loading style={{ background: 'none' }}></Loading>}
            {growthRecordStatus === StatusEnum.Success && (
              <Table
                columns={columns}
                rows={rows}
                buttonText="Export"
                svg={exportSvg}
                totalPageCount={1}
                currentPage={1}
                onRowClick={(id, columnId, original) => {
                  if (columnId === 'email') {
                    window.location.href = `mailto:${original.email}`
                  } else if (columnId === 'phone') {
                    window.location.href = `tel:${original.phone}`
                  } else if (columnId === 'firstname' || columnId === 'lastname') {
                    navigate('/admin/patients/' + id)
                  }
                }}
                onButtonClick={() => {
                  exportReports()
                }}
                onPageChange={(page, pageSize) => {}}
                initalButtonChildren={'No Record Found'}
                noDataFoundContent="No Record Found"
                onInitialButtonClicked={() => {}}
              ></Table>
            )}
          </div>
        )}
      </Content>
    </Layout>
  )
}

export default Growth
